/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext, useState } from 'react';
import { Modal } from 'src/components/core/modal';
import { RawHtml } from '@untile/react-core/components/raw-html';
import { useCreateSubmission } from 'src/api/me/submission';
import { useTranslation } from 'next-i18next';

/**
 * `CooldownModal` type.
 */

type CooldownModal = {
  isCooldownModalOpen: boolean;
  setIsCooldownModalOpen: (value: boolean) => void;
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * Export `CooldownModalContext`.
 */

export const CooldownModalContext: Context<CooldownModal> = createContext(
  {} as CooldownModal
);

/**
 * Export `useCooldownModal` hook.
 */

export function useCooldownModal(): CooldownModal {
  return useContext(CooldownModalContext);
}

/**
 * Export `CooldownModalProvider` component.
 */

export function CooldownModalProvider({ children }: Props) {
  const [isCooldownModalOpen, setIsCooldownModalOpen] = useState(false);
  const { t } = useTranslation();
  const { mutate: createSubmission } = useCreateSubmission();

  return (
    <CooldownModalContext.Provider
      value={{ isCooldownModalOpen, setIsCooldownModalOpen }}
    >
      {children}

      <Modal
        actions={[
          {
            label: t('common:modal.createWarning.action'),
            onClick: () => {
              setIsCooldownModalOpen(false);
              createSubmission();
            }
          }
        ]}
        description={
          <RawHtml>{t('common:modal.createWarning.description')}</RawHtml>
        }
        isOpen={isCooldownModalOpen}
        onRequestClose={() => setIsCooldownModalOpen(false)}
        title={t('common:labels.alert')}
      />
    </CooldownModalContext.Provider>
  );
}

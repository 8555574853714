/**
 * Module dependencies.
 */

import { Container } from 'src/components/core/container';
import { Link } from 'src/components/core/links/link';
import { Trans, useTranslation } from 'next-i18next';
import { ifProp } from 'styled-tools';
import { media } from '@untile/react-core/styles/media';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * Environment constant.
 */

const brpAssociationUrl = process.env.NEXT_PUBLIC_BRP_ASSOCIATION_URL;
const email = process.env.NEXT_PUBLIC_EMAIL;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Container).attrs({ as: 'footer' })`
  ${textStyles.caption}

  border-top: 1px solid var(--color-grey200);
  color: var(--color-grey600);
  display: flex;
  grid-area: footer;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 8px;

  ${media.max.md`
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  `}
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link).attrs({ as: 'a' })<{ bold?: boolean }>`
  ${textStyles.caption}

  appearance: none;
  background: none;
  border: none;
  color: var(--color-grey600);

  :focus,
  :hover {
    color: var(--color-grey900);
  }

  ${ifProp('bold', 'font-weight: 700;')}
`;

/**
 * `LeftContent` styled component.
 */

const LeftContent = styled.div`
  width: 100%;
`;

/**
 * `RightContent` styled component.
 */

const RightContent = styled.div`
  text-align: center;
  width: 100%;

  ${media.min.md`
    text-align: right;
  `}
`;

/**
 * `SupportEmail` styled component.
 */

const SupportEmail = styled.span`
  display: block;

  ${media.min.md`
    display: inline-block;
    margin-left:40px;
  `}
`;

/**
 * Email components.
 */

const emailComponents = { Link: <StyledLink bold href={`mailto:${email}`} /> };

/**
 * `Footer` component.
 */

export function Footer() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <LeftContent>
        {`Copyright © ${new Date().getFullYear()} Metamorfose | `}

        <StyledLink href={brpAssociationUrl}>
          {t('footer.labels.brpAssociation')}
        </StyledLink>
      </LeftContent>

      <RightContent>
        <div>
          <Trans
            components={[
              <StyledLink
                bold
                href={process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS_URL}
                key={'terms-link'}
                target={'_blank'}
              />,
              <StyledLink
                bold
                href={process.env.NEXT_PUBLIC_PRIVACIES_URL}
                key={'privacy-link'}
                target={'_blank'}
              />
            ]}
            i18nKey={'footer.labels.legalLink'}
          />
        </div>

        <SupportEmail>
          <Trans
            components={emailComponents}
            i18nKey={'footer.labels.supportEmail'}
            values={{ email }}
          />
        </SupportEmail>
      </RightContent>
    </Wrapper>
  );
}

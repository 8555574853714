/**
 * Module dependencies.
 */

import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { Trans } from 'next-i18next';
import { media } from '@untile/react-core/styles/media';
import React from 'react';
import metamorfoseSvg from 'src/assets/metamorfose-logo.svg';
import promotorsSvg from 'src/assets/promotors.svg';
import styled from 'styled-components';

/**
 * Environment constant.
 */

const email = process.env.NEXT_PUBLIC_EMAIL;

/**
 * `Props` type.
 */

type Props = {
  className?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Text).attrs({
  as: 'div',
  variant: 'caption'
})`
  text-align: center;

  ${media.min.md`
    display: grid;
    grid-column-gap: 18px;
    grid-template-columns: repeat(3, max-content);
    text-align: left;
  `}
`;

/**
 * `Link` styled component.
 */

const Link = styled.a`
  font-weight: 700;
  outline: none;
  text-decoration: none;
  transition: color var(--transition-default);

  :focus,
  :focus-within,
  :hover {
    color: var(--color-grey800);
    text-decoration: underline;
  }
`;

/**
 * `Divider` styled component.
 */

const Divider = styled.span`
  background-color: var(--color-grey300);
  height: 100%;
  width: 1px;

  ${media.max.md`
    display: none;
  `}
`;

/**
 * `Promotors` styled component.
 */

const Promotors = styled.div`
  align-self: center;

  ${media.max.md`
    display: none;
  `}
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(Svg).attrs({
  icon: metamorfoseSvg,
  size: '182px'
})`
  color: var(--text-color);
  display: block;
  margin-bottom: 16px;

  ${media.max.md`
    display: none;
  `}
`;

/**
 * Export `Footer` component.
 */

export const Footer = ({ className }: Props) => (
  <Wrapper className={className}>
    <div>
      <Logo />

      <span>
        <Trans
          components={[
            <Link
              href={`mailto:${email}`}
              key={'email-contact'}
              target={'_blank'}
            />
          ]}
          i18nKey={'auth:labels.contact'}
          transSupportBasicHtmlNodes
          values={{ email }}
        />
      </span>

      <div>
        <Trans
          components={[
            <Link
              href={process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS_URL}
              key={'terms-link'}
              target={'_blank'}
            />,
            <Link
              href={process.env.NEXT_PUBLIC_PRIVACIES_URL}
              key={'privacy-link'}
              target={'_blank'}
            />
          ]}
          i18nKey={'footer.labels.legalLink'}
        />
      </div>
    </div>

    <Divider />

    <Promotors>
      <Svg icon={promotorsSvg} size={'177px'} />
    </Promotors>
  </Wrapper>
);

/**
 * Module dependencies.
 */

import { AppProps } from 'src/types/app';
import { CooldownModalProvider } from 'src/providers/cooldown-modal';
import { DefaultSeo } from 'next-seo';
import { GlobalStyle } from 'src/styles/global';
import { GlobalStyle as GlobalStyleUntile } from '@untile/react-core/styles/global';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';

import { Layout } from 'src/components/layout';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SessionProvider } from 'src/providers/session';
import { ToastProvider } from 'src/providers/toast';
import { appWithTranslation } from 'next-i18next';
import { seoDefaultConfig } from 'src/core/constants/seo-default-config';
import Head from 'next/head';
import React, { useState } from 'react';

/**
 * `App` component.
 */

function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 1000 * 60 * 15
          }
        }
      })
  );

  return (
    <>
      <Head>
        <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'} />

        <meta content={'text/html;charset=utf-8'} httpEquiv={'Content-Type'} />

        <meta
          content={'width=device-width, initial-scale=1'}
          name={'viewport'}
        />

        <meta content={'true'} name={'HandheldFriendly'} />

        <link
          href={'/favicon.ico'}
          rel={'shortcut icon'}
          type={'image/x-icon'}
        />

        <link
          href={'/favicons/apple-touch-icon.png'}
          rel={'apple-touch-icon'}
          sizes={'180x180'}
        />

        <link
          href={'/favicons/favicon-32x32.png'}
          rel={'icon'}
          sizes={'32x32'}
          type={'image/png'}
        />

        <link
          href={'/favicons/favicon-16x16.png'}
          rel={'icon'}
          sizes={'16x16'}
          type={'image/png'}
        />

        <link href={'/site.webmanifest'} rel={'manifest'} />

        <link
          color={'#0580bd'}
          href={'/favicons/safari-pinned-tab.svg'}
          rel={'mask-icon'}
        />

        <meta content={'#0580bd'} name={'msapplication-TileColor'} />

        <meta content={'#0580bd'} name={'theme-color'} />
      </Head>

      <GlobalStyleUntile />

      <GlobalStyle />

      <DefaultSeo {...seoDefaultConfig} />

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps?.dehydratedState}>
          <SessionProvider>
            <ToastProvider>
              <CooldownModalProvider>
                <Layout layout={pageProps?.layout}>
                  <Component {...pageProps} />
                </Layout>
              </CooldownModalProvider>
            </ToastProvider>
          </SessionProvider>
        </Hydrate>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

/**
 * Export `App` component.
 */

export default appWithTranslation(App);

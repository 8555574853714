/**
 * Module dependencies.
 */

import { Button, ButtonProps } from 'src/components/core/button';
import { ModalPortal } from './portal';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { ifProp } from 'styled-tools';
import { media } from '@untile/react-core/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import React, { ReactNode, useEffect, useRef } from 'react';
import closeIcon from 'src/assets/24/cancel.svg';
import styled, { css } from 'styled-components';

/**
 * `Action` props.
 */

type Action = Partial<ButtonProps> & {
  label: string;
};

/**
 * `Props` types.
 */

type Props = {
  actions: [Action, Action?];
  className?: string;
  description: ReactNode;
  isOpen?: boolean;
  onRequestClose: () => void;
  title: string;
};

/**
 * Export `Underlay` styled component.
 */

export const Underlay = styled.div<Pick<Props, 'isOpen'>>`
  align-items: flex-start;
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  background-color: ${transparentize('blue900', 0.5)};
  cursor: pointer;
  display: flex;
  inset: 0;
  overflow: auto;
  position: fixed;
  scroll-snap-type: y mandatory;
  z-index: var(--z-index-modal);

  ${ifProp(
    'isOpen',
    css`
      animation-name: fadeIn;
    `,
    css`
      animation-name: fadeOut;
      opacity: 0;
      pointer-events: none;
    `
  )}
`;

/**
 * `ModalBodyWrapper`styled component.
 */

const ModalBodyWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  max-width: 100vw;
  min-height: 100%;
  width: 100%;

  ${media.min.sm`
    align-items: center;
    padding: 40px 0;
  `}
`;

/**
 * `ModalBody` styled component.
 */

const ModalBody = styled.div<Pick<Props, 'isOpen'>>`
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  background-color: var(--color-white);
  border-radius: 8px 8px 0 0;
  box-shadow: var(--box-shadow-large);
  cursor: default;
  display: grid;
  grid-column-gap: 16px;
  grid-template-areas:
    'title closeButton'
    'description description'
    'actions actions';
  grid-template-columns: 1fr 24px;
  max-width: 470px;
  overflow: hidden;
  padding: 24px 16px 48px;
  position: relative;
  width: 100%;

  ${ifProp(
    'isOpen',
    css`
      animation-name: slideInUp;
    `,
    css`
      animation-name: fadeOut;
      opacity: 0;
      pointer-events: none;
    `
  )}

  ${media.min.sm`
    border-radius: 8px;
    padding: 40px 32px 32px;

    ${ifProp(
      'isOpen',
      css`
        animation-name: fadeIn;
      `
    )}
  `}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled.button`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  color: var(--color-grey500);
  cursor: pointer;
  grid-area: closeButton;
  height: 24px;
  line-height: 0;
  padding: 0;
  position: relative;
  top: 5px;
  transition: color var(--transition-default);

  :hover,
  :focus {
    color: var(--color-grey800);
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  fontWeight: 700,
  variant: 'h2'
})`
  grid-area: title;
  margin-bottom: 38px;

  ${media.min.sm`
    margin-bottom: 44px;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({
  as: 'p',
  variant: 'paragraph1'
})`
  grid-area: description;
  margin-bottom: 48px;

  ${media.min.sm`
    margin-bottom: 56px;
  `}
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  display: flex;
  gap: 8px;
  grid-area: actions;
`;

/**
 * Export `Modal` component.
 */

export function Modal(props: Props) {
  const { actions, className, description, isOpen, onRequestClose, title } =
    props;

  const underlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      underlayRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onRequestClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onRequestClose]);

  return (
    <ModalPortal isOpen={!!isOpen} preventBodyScroll={!!isOpen}>
      <Underlay
        isOpen={!!isOpen}
        onClick={() => onRequestClose()}
        ref={underlayRef}
        tabIndex={-1}
      >
        <ModalBodyWrapper>
          <ModalBody
            className={className}
            isOpen={!!isOpen}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
            role={'dialog'}
          >
            <Title>{title}</Title>

            <CloseButton onClick={() => onRequestClose()}>
              <Svg icon={closeIcon} size={'24px'} />
            </CloseButton>

            <Description>{description}</Description>

            <Actions>
              {actions
                .filter((action): action is Action => !!action)
                .map(({ label, ...action }, index) => (
                  <Button
                    colorTheme={index === 0 ? 'primary' : 'secondary'}
                    key={index + label}
                    stretch
                    {...action}
                  >
                    {label}
                  </Button>
                ))}
            </Actions>
          </ModalBody>
        </ModalBodyWrapper>
      </Underlay>
    </ModalPortal>
  );
}

/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

/**
 * Export `createReactPortal`.
 */

export function createReactPortal(children: ReactNode, elementId: string) {
  if (
    !(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    )
  ) {
    return null;
  }

  return createPortal(
    children,
    document.getElementById(elementId) as HTMLElement
  );
}

/**
 * Module dependencies.
 */

import { colors } from 'src/styles/colors';
import colorUtil from 'color';

/**
 * Export `transparentize`.
 */

export function transparentize(
  colorName: keyof typeof colors,
  opacity: number
): string {
  return colorUtil(colors[colorName]).alpha(opacity).rgb().string();
}

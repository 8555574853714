/**
 * Export `createCssVariables`.
 */

export function createCssVariables(
  list: { [key: string]: string },
  suffix: string
) {
  return Object.entries(list).reduce((previous: string, [name, value]) => {
    return `${previous} --${suffix}-${name}: ${value};`;
  }, '');
}
